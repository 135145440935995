/* eslint-disable react/jsx-props-no-spreading */
import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './HeroSearchInput.module.css';

// Components
import { Picto, availablePictos } from '../../atoms/Picto/Picto';

const HeroSearchInput = forwardRef(({
  name,
  label,
  value,
  onBlur: onBlurFromProps,
  icon,
  iconClose,
  className,
  animatedLabel,
  theme,
  children,
  onChange,
  isLoading,
  ...props
}, ref) => {
  const [internValue, setInternValue] = useState(value || '');
  const [isFocused, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
    setIsOpen(true);
  };

  const onBlur = (e) => {
    if (onBlurFromProps) onBlurFromProps(e);
    setIsFocus(false);
  };

  const handleOnChange = (e) => {
    onChange(e.target.value);
    setInternValue(e.target.value);
  };

  const handleClose = () => {
    // eslint-disable-next-line no-param-reassign
    ref.current.value = null;
    onChange(null);
    setInternValue(null);
    setIsOpen(false);
    setIsFocus(false);
  };

  return (
    <div className={cn([
      styles.container, styles[theme], className,
    ])}
    >
      <div className={styles.labelContainer}>
        <label
          htmlFor={name}
          className={cn([
            styles.label,
            isFocused ? styles.labelTop : '',
            internValue ? styles.labelHide : '',
          ])}
        >
          {label}
        </label>
        <button type="button" onClick={handleClose} className={styles.icon} aria-label="close">
          <Picto icon={isOpen || !!internValue ? iconClose : icon} />
        </button>
        <input
          {...props}
          ref={ref}
          onChange={handleOnChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type="text"
          id={name}
          name={name}
          autoComplete="off"
          className={cn([styles.textField, isLoading ? styles.isLoading : ''])}
          defaultValue={internValue}
        />
      </div>

      {children(isOpen)}
    </div>
  );
});

HeroSearchInput.displayName = 'HeroSearchInput';

HeroSearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  animatedLabel: PropTypes.bool,
  children: PropTypes.func,
  icon: PropTypes.oneOf(Object.keys(availablePictos)),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  iconClose: PropTypes.oneOf(Object.keys(availablePictos)),
  isLoading: PropTypes.bool,
};

HeroSearchInput.defaultProps = {
  value: '',
  onBlur: () => {},
  onChange: () => {},
  label: '',
  className: '',
  animatedLabel: true,
  children: () => {},
  icon: 'search',
  theme: 'dark',
  iconClose: 'cross',
  isLoading: false,
};

export default HeroSearchInput;
