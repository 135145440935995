import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { debounce, startCase } from 'lodash';

// Utils
import { cn } from 'utils';
import { URL_SEARCH_PAGES } from '../../../src/constants';

// Components
import HeroSearchInput from '../HeroSearchInput/HeroSearchInput';
import SearchResults from '../../atoms/SearchResults/SearchResults';
import SearchResultList from '../../atoms/SearchResultList/SearchResultList';

// Services
import { useContentTranslation } from '../../../src/services/content-translations';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';

// Style
import styles from './SearchBar.module.css';

const LIST_ITEMS_LIMIT = 3;

const sortSearchPagesInCityOrRegion = (dataFromAPI) => (
  dataFromAPI?.data?.reduce((acc, dataItem) => {
    const isCityPage = dataItem?.data?.city?.length > 0;
    const isRegionPage = dataItem?.data?.region?.length > 0;

    return ({
      cities: isCityPage ? acc.cities.concat([dataItem]) : acc.cities,
      regions: isRegionPage ? acc.regions.concat([dataItem]) : acc.regions,
    });
  }, { cities: [], regions: [] }));

function SearchBar({
  // This is a temporary disable, this feature will change in a near future
  // eslint-disable-next-line no-unused-vars
  cities, regions, label, theme, className,
}) {
  // Hooks
  const ref = useRef(null);
  const { locale, query } = useRouter();
  const { isMobile } = useResponsiveQueries();
  const { t } = useTranslation();
  // States
  const [search, setSearch] = useState(startCase(query?.slug));

  const { data: dataSearchPages, isFetching: isFetchingSearchPages } = useContentTranslation(
    {
      contentTypeId: 'search', search: search || null, pageNumber: 1, pageSize: 10,
    },
    locale,
    {
      enabled: !!search,
      select: sortSearchPagesInCityOrRegion,
    },
  );

  const onSearchChange = (value) => setSearch(value);
  const handleOnSearchChange = debounce(onSearchChange, 500);

  useEffect(() => {
    const formattedSlug = startCase(query?.slug);
    if (formattedSlug !== ref.current.value) {
      ref.current.value = formattedSlug;
    }
  }, [query?.slug]);

  return (
    <div className={cn([styles.searchBarContainer, styles[theme], className])}>
      <div className={styles.searchBar}>
        <HeroSearchInput
          name="hero-search"
          label={label}
          icon="search"
          value={search}
          onChange={handleOnSearchChange}
          ref={ref}
          isLoading={isFetchingSearchPages}
          theme={theme}
        >
          {(isFocused) => (
            <AnimatePresence initial={false}>
              {search || isFocused ? (
                <motion.div
                  className="flex-auto"
                  initial={{
                    opacity: 0,
                    height: 0,
                    transform: isMobile ? 'translateX(16px)' : 'translateY(30px)',
                  }}
                  animate={{
                    opacity: 1,
                    height: 'auto',
                    transform: isMobile ? 'translateX(0)' : 'translateY(0)',
                  }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    transform: isMobile ? 'translateX(16px)' : 'translateY(20px)',
                  }}
                  transition={{ duration: 0.2 }}
                >
                  <SearchResults>
                    {!search
                      ? null
                      : (
                        <>
                          <SearchResultList
                            name="city"
                            label={t('heroSectionBlock.cities')}
                            list={dataSearchPages?.cities?.slice(0, LIST_ITEMS_LIMIT)}
                            isLoading={isFetchingSearchPages}
                            keyProp="city"
                            theme={theme}
                            emptyListLabel={t('heroSectionBlock.emptySearchList')}
                          />
                          <SearchResultList
                            name="region"
                            label={t('heroSectionBlock.regions')}
                            list={dataSearchPages?.regions?.slice(0, LIST_ITEMS_LIMIT)}
                            isLoading={isFetchingSearchPages}
                            keyProp="region"
                            theme={theme}
                            emptyListLabel={t('heroSectionBlock.emptySearchList')}
                          />
                        </>
                      )}
                    <a className={cn([styles.emptyLink])} href={`/${locale}/${URL_SEARCH_PAGES[locale]}`}>
                      {t('heroSectionBlock.linkLabel')}
                    </a>
                  </SearchResults>
                </motion.div>
              ) : null}
            </AnimatePresence>
          )}
        </HeroSearchInput>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
  cities: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      city: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  })),
  regions: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      region: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  })),
  label: PropTypes.string,
};

SearchBar.defaultProps = {
  theme: 'dark',
  className: null,
  cities: [],
  regions: [],
  label: null,
};

export default SearchBar;
