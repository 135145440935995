import PropTypes from 'prop-types';

// Styles
import styles from './SearchResults.module.css';

function SearchResults({ children }) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}

SearchResults.propTypes = {
  children: PropTypes.node,
};

SearchResults.defaultProps = {
  children: null,
};

export default SearchResults;
