import axios from 'axios';

/**
 * @description Create Axios instance
 * @returns {AxiosInstance}
 */
const cmsClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS_API_URL,
  headers: {
    Accept: 'application/json',
  },
  timeout: 30000,
});

export default cmsClient;
